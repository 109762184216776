export const length = (param) => {
  return (value) => value?.length === param;
};
export const maxSize = (size) => {
  return (value) => (!value || value?.size < size);
};
export const notRequiredOrLength = (size) => {
  return (value) => (!value || value?.length === size);
};

