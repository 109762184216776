<template>
  <div class="add-photo">
    <!-- <v-file-input 
      v-model="previewImage"
      @change="onChange"
      label="Добавить фото"
      color="#5b6cff"
      small-chips
      counter
      outlined
      :show-size="1000"
      prepend-icon="mdi-camera"
    ></v-file-input>
    <v-img :src="previewImageUrl"></v-img> -->
    <input type="file" id="add-photo" multiple @change="onChange"/>
    <label for="add-photo">
      <img src="@/assets/images/camera.svg" alt="" />
      Добавить фото
    </label>
    <div class="images mt-4">
      <v-chip v-for="(image, i) in images" :key="i" class="image mb-2"
              close
              color="blue"
              outlined
              @click:close="removeImage(i)"
      >
          {{image.name}}
      </v-chip>
<!--      <div v-for="(image, i) in images" :key="i" class="image">-->
<!--        {{ image.name }}-->
<!--        <span @click="removeImage(i)" class="remove">✕</span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import {
  maxSize,
} from '@/services/validators';

import { newsAddPhotoApi } from '@/api/news';

export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    previewImage: null,
    previewImageUrl: null,
    // images: [],
    maxUploadSize: 1024 * 1024 * 3, /** 3 Mb */
  }),
  watch: {
    images: {
      handler(images) {
        const files = images.map((image) => image.file);
        this.$emit('input', files);
      },
    },
  },
  methods: {
    onChange(e) {
      // this.previewImageUrl = URL.createObjectURL(this.previewImage);
      
      const files = Array.from(e.target.files);
      const checkSize = maxSize(this.maxUploadSize);

      files.forEach((file) => {
        if (checkSize(file)) {
          this.readFile(file);
        }
      });
    },
    readFile (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image()
        img.src = reader.result

        img.onload = () => {
          let size = ''
          if (Number.parseInt(file.size) >= 1024 * 1024) {
            size = `${Math.round(file.size * 10 / (1024 * 1024)) / 10}mb`
          } else {
            size = `${Math.round(file.size * 10 / 1024) / 10}kb`
          }
          const newFile = {
            src: reader.result,
            size,
            info: `${img.naturalWidth} x ${img.naturalHeight}`,
            name: file.name,
            file
          }
          this.$emit('push', newFile)
        }
      }
      reader.onerror = () => {
        // this.images = [];
      }
      reader.readAsDataURL(file)
    },
    removeImage(i) {
      this.$emit('delete',i);
    },
  },
};
</script>

<style lang="scss">
  .add-photo {
    .image {
      padding: .5rem 1rem;
    }
    .remove {
      cursor: pointer;
      padding: .25rem;
      color: red;
    }
  }
</style>